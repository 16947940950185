import { Injectable } from '@angular/core';
import { ExtendConfig } from 'app/config/extend';

export interface StepsInterface {
    min: number;
    step: number;
}

export interface HistoryInterface {
    items: {
        from: number;
        to: number;
        name: string;
    }[]
    defaultDuration?: {
        from: number;
        to: number;
    }
}

@Injectable()
export class BetConfig extends ExtendConfig {
    protected static config: { steps: StepsInterface[], history: HistoryInterface } = {
        steps: [
            { min: 0, step: 1 },
            { min: 5, step: 5 },
            { min: 25, step: 25 },
            { min: 100, step: 100 },
            { min: 1000, step: 1000 }
        ],
        history: {
            items: [
                {
                    from: null,
                    to: null,
                    name: gettext('All time'),
                },
                {
                    from: 0,
                    to: 0,
                    name: gettext('Today')
                },
                {
                    from: 1,
                    to: 1,
                    name: gettext('Yesterday')
                },
                {
                    from: 2,
                    to: 2,
                    name: gettext('Two days ago')
                },
                {
                    from: 7,
                    to: 0,
                    name: gettext('1 week')
                },
                {
                    from: 14,
                    to: 0,
                    name: gettext('2 weeks')
                },
                {
                    from: 31,
                    to: 0,
                    name: gettext('1 month')
                }
            ]
        }
    };

    protected static errors: any[] = [
        gettext('The number of events in the coupon exceeds the maximum'),
        gettext('The coefficient is more than the maximum allowable')
    ];
}
