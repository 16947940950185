export const environment = {
    env: 'qa',
    production: false,
    test: false,
    qa: true,
    dev: false,
    sentry: 'http://818bde3b01075ffd4d7a084ecdfa8fc4@betradar.1.keepalive.proxy:8080/sentry3/23',
    connection: {
        url: 'https://qa-brserver2.egamings.com',
        apiUrl: '/',
        streamingApiUrl: 'wss://qa-sgstream.egamings.com/ws',
        sportsBookApiUrl: 'https://qa-sgapi.egamings.com/api/v1',
        minify: true
    },
    failoverConnection: null,
};
