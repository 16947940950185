import { Injectable, Injector } from '@angular/core';
import { SiteConfig } from 'app/config/site.config';

export let AppInjector: Injector;

@Injectable({
    providedIn: 'root'
})
export class InjectorService {

    constructor(private injector: Injector, private siteConfig: SiteConfig) {
        AppInjector = injector;
    }
}
