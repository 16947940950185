import { IModulesConfig, POSITIONS, MODULES } from '@egamings/sportsbook-core/sportsbook-module-system';

import {
    EnumBannerType,
    EnumBannerPosition,
} from '@egamings/sportsbook-core/sportsbook-banners-components';

export const modulesConfig: IModulesConfig = {
    [POSITIONS.HEADER]: [
        {
            module: MODULES.MAIN_MENU,
            params: {
                items: {
                    left: [
                        {
                            type: 'icon',
                            icon: 'home',
                            sref: 'home',
                            className: 'sb-section-main-menu__item_home'
                        },
                        {
                            type: 'link',
                            title: gettext('Live Betting'),
                            sref: 'live.events'
                        },
                        {
                            type: 'link',
                            title: gettext('Upcoming'),
                            sref: 'calendar.upcoming'
                        },
                        {
                            type: 'link',
                            title: gettext('Results'),
                            sref: 'results'
                        }
                    ],
                    right: [
                        {
                            type: 'widget',
                            widget: 'delimiter',
                            authroized: true,
                        },
                        {
                            type: 'link',
                            title: gettext('Open Bets'),
                            sref: 'profile.open-bets',
                            authroized: true,
                        },
                        {
                            type: 'widget',
                            widget: 'delimiter',
                            authroized: true,
                        },
                        {
                            type: 'link',
                            title: gettext('Betting History'),
                            sref: 'profile.history',
                            authroized: true,
                        },
                        {
                            type: 'widget',
                            widget: 'delimiter'
                        },
                        {
                            type: 'widget',
                            widget: 'odds-drop-down'
                        },
                        {
                            type: 'widget',
                            widget: 'delimiter'
                        },
                        {
                            type: 'widget',
                            widget: 'gmt-drop-down'
                        },
                    ],
                }
            }
        },
        {
            module: MODULES.MOBILE_MAIN_MENU_V2,
            desktop: false,
            mobile: true,
        },
    ],
    [POSITIONS.LEFT_SIDE]: [
        {
            module: MODULES.SEARCH,
            params: {
                type: 'dropdown-left'
            }
        },
        {
            module: MODULES.TOP_EVENTS,
        },
        {
            module: MODULES.LEFT_MENU,
            params: {
                items: [
                    {
                        title: gettext(`Today's Events`),
                        link: 'calendar.today',
                        icon: 'down-arrow'
                    },
                    {
                        icon: 'star',
                        title: gettext('My Favorites'),
                        link: 'favourite.matches'
                    }
                ]
            }
        },
        {
            module: MODULES.DELIMITER
        },
        {
            module: MODULES.SPORTS_MENU_TABS
        }
    ],
    [POSITIONS.RIGHT_SIDE]: [
        {
            module: MODULES.BET_SLIP,
            params: {
                resetAfterBet: true,
                showUserBalance: true,
            }
        },
        {
            module: MODULES.WIDGET_MATCH_STATS,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live']
        },
        {
            module: MODULES.BANNERS,
            params: {
                position: 'right'
            }
        },
        {
            module: MODULES.GAMES_EVENTS,
            params: {
                config: {
                    queryParams: {
                        status: ['not_started'],
                        liveodds: true,
                        start_time: {
                            gte: {
                                '@pattern': 'now() + minutes(5)'
                            }
                        },
                        limit: 10,
                        order: {
                            start_time: true,
                            sort: true,
                        }
                    },
                    subscribe: true,
                    refetch: 30000,
                }
            }
        },
    ],
    [POSITIONS.MAIN_TOP]: [
        {
            module: MODULES.BANNERS,
            params: {
                type: EnumBannerType.cards,
                position: EnumBannerPosition.main_page_top
            },
            routes: ['home', 'home.upcoming', 'home.live', 'home.highlights','home.promotions']
        },
        {
            module: MODULES.HOME_EVENTS,
            routes: ['home', 'home.upcoming', 'home.live', 'home.highlights', 'home.promotions'],
            params: {
                hideIcons: false
            },
            paramsMobile: {
                hideIcons: false
            }
        },
        {
            module: MODULES.BREADCRUMBS,
            paramsMobile: {
                showReturnBackBtn: true
            },
            routesExclude: ['home', 'home.upcoming', 'home.live', 'home.highlights','home.promotions', 'profile.history', 'profile.open-bets', 'profile.bonuses', 'home.promotions']
        },
        {
            module: MODULES.GAME_HEADER,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live'],
        },
        {
            module: MODULES.GAME_TIPS,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live'],
        },
    ],
    [POSITIONS.MOBILE_RIGHT_PANEL]: [
        {
            module: MODULES.BET_SLIP,
            params: {
                showUserBalance: true,
                resetAfterBet: true,
            }
        },
    ],
    [POSITIONS.MAIN_BOTTOM]: [
        {
            module: MODULES.BET_ACCUMULATORS,
            routes: ['home', 'home.upcoming', 'home.live', 'home.highlights','home.promotions'],
        },
    ]
};

