import { IModulesConfig, POSITIONS, MODULES, ROUTES } from '@egamings/sportsbook-core/sportsbook-module-system';

import {
    EnumBannerType,
    EnumBannerPosition,
} from '@egamings/sportsbook-core/sportsbook-banners-components';

import {
    EMobileMainMenuItemType,
    EnumMainMenuItemType,
    EnumMainMenuWidgetType,
    EMobileMainMenuV2ItemTriggers,
} from '@egamings/sportsbook-core/sportsbook-menu-components';
import { EnumBetradarWidgetTheme } from '@egamings/sportsbook-core/sportsbook-common';

const carouselMenuItems: any[] = [
    {
        text: gettext('All sports'),
        icon: `<svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" style="stroke: none!important;">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5633 11.4538H13.5481C11.5545 11.4538 9.95803 13.102 9.95803 15.0959V16.4187C9.95297 16.4526 9.93781 16.4829 9.93781 16.5183C9.93781 16.5529 9.95297 16.5825 9.95803 16.6157V17.0746C9.95803 17.8562 9.65548 18.6045 9.17169 19.1609C8.68574 19.718 8.02361 20.0788 7.26977 20.1315L7.31526 20.851L7.26544 20.1315L7.04954 20.1387C6.29137 20.1373 5.58519 19.863 4.90933 19.3536C3.89844 18.5937 3.00813 17.2926 2.39726 15.8428C1.78206 14.3959 1.44269 12.8026 1.44413 11.5137C1.44486 8.72675 2.57633 6.21473 4.4075 4.39116C6.2401 2.56903 8.76373 1.44544 11.5531 1.44472C14.0572 1.44472 16.3613 2.49831 18.1484 4.19271C19.9341 5.88567 21.1818 8.21511 21.5486 10.7004L21.5559 10.8C21.5559 10.984 21.4786 11.1312 21.3436 11.2662C21.2071 11.3997 21.0251 11.4538 20.8222 11.4538H13.5633ZM21.5111 17.2269V20.8603C21.5111 21.2587 21.1674 21.5567 20.7688 21.5567H16.4364C15.2407 21.5567 14.2904 20.6128 14.2904 19.4171V17.2269C16.931 17.2269 19.8034 17.2269 21.5111 17.2269ZM12.8463 15.7836C12.3372 15.7836 11.8535 15.7836 11.4022 15.7836V15.0959C11.4022 14.1484 12.0036 13.3301 12.8463 13.0284V15.7836ZM14.2904 12.8971H20.8222C22.0238 12.8971 22.9935 11.9618 23 10.7726L22.9769 10.4753V10.4818C22.5574 7.66089 21.1602 5.05867 19.1427 3.14273C17.1274 1.22823 14.4702 0 11.5531 0C5.18444 0 0.00144413 5.14238 0 11.5123C0.00577654 13.5531 0.673689 15.9741 1.85427 17.9659C2.44709 18.9603 3.1706 19.8472 4.03708 20.5039C4.90067 21.1591 5.926 21.5827 7.04954 21.582L7.36508 21.5712L7.31598 20.8632L7.36508 21.5712C8.52978 21.4911 9.55511 20.9239 10.2627 20.1062C10.9393 19.3254 11.3509 18.3094 11.3877 17.2269C11.8455 17.2269 12.3343 17.2269 12.8463 17.2269V19.4171C12.8463 21.4102 14.4428 23 16.4364 23H20.7688C21.966 23 22.9552 22.0561 22.9552 20.8603V16.5305C22.9552 16.3407 22.8686 16.1423 22.7336 16.0073C22.5993 15.8731 22.4036 15.7836 22.2129 15.7836C20.9334 15.7836 17.435 15.7836 14.2904 15.7836V12.8971Z" />
        </svg>
        `,
        link: 'home'
    }
];

export const modulesConfig: IModulesConfig = {
    [POSITIONS.HEADER]: [
        {
            module: MODULES.MAIN_MENU,
            params: {
                items: {
                    left: [
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Home'),
                            sref: 'home'
                        },
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Live Betting'),
                            sref: 'live.events'
                        },
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Upcoming'),
                            sref: 'calendar.upcoming'
                        },
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Results'),
                            sref: 'results'
                        },
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Promotions'),
                            sref: 'promotions'
                        }
                    ],
                    right: [
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('My bets'),
                            sref: 'profile.open-bets',
                            authroized: true
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.delimiter,
                            authroized: true
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: 'markets-view-drop-down',
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.delimiter,
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.oddsDropDown,
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.delimiter,
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.gmtDropDown,
                        }
                    ]
                }
            }
        },
        {
            module: MODULES.MOBILE_MAIN_MENU_V2,
            desktop: false,
            mobile: true,
            params: {
                items: [
                    {
                        icon: 'home-v2',
                        type: EMobileMainMenuItemType.link,
                        link: 'home'
                    },
                    {
                        icon: 'live-v2',
                        type: EMobileMainMenuItemType.link,
                        link: 'live.events'
                    },
                    {
                        icon: 'sports',
                        type: EMobileMainMenuItemType.trigger,
                        trigger: EMobileMainMenuV2ItemTriggers.sports,
                    },
                    {
                        icon: 'user-v2',
                        type: EMobileMainMenuItemType.trigger,
                        trigger: EMobileMainMenuV2ItemTriggers.user,
                        authorized: true
                    },
                    {
                        icon: 'search-v2',
                        type: EMobileMainMenuItemType.trigger,
                        trigger: EMobileMainMenuV2ItemTriggers.search
                    },
                    {
                        icon: 'settings',
                        type: EMobileMainMenuItemType.trigger,
                        trigger: EMobileMainMenuV2ItemTriggers.settings
                    },
                    {
                        icon: 'menu-v2',
                        type: EMobileMainMenuItemType.trigger,
                        trigger: EMobileMainMenuV2ItemTriggers.menu
                    },
                ],
                menuItems: [
                    {
                        type: EMobileMainMenuItemType.link,
                        title: gettext('Upcoming'),
                        link: 'calendar.upcoming',
                        icon: 'calendar'
                    },
                    {
                        type: EMobileMainMenuItemType.link,
                        title: gettext('Today\'s Events'),
                        link: 'calendar.today',
                        icon: 'clock'
                    },
                    {
                        type: EMobileMainMenuItemType.link,
                        title: gettext('Results'),
                        link: 'results',
                        icon: 'statistics'
                    },
                    {
                        type: EMobileMainMenuItemType.link,
                        title: gettext('My Favorites'),
                        link: 'favourite.matches',
                        icon: "star"
                    },
                    {
                        type: EMobileMainMenuItemType.link,
                        title: gettext('Promotions'),
                        link: 'promotions',
                        icon: "promo"
                    },
                ],
                viewItems: [
                    {
                        text: gettext('Euro View'),
                        value: 'euro',
                    },
                    {
                        text: gettext('Asian View'),
                        value: 'asian',
                    },
                ],
            }
        },
        // {
        //     module: MODULES.BREADCRUMBS,
        //     mobile: true,
        //     paramsMobile: {
        //         showReturnBackBtn: true
        //     },
        //     routesExclude: ['home', 'profile.history', 'profile.open-bets']
        // },
        {
            module: MODULES.CAROUSEL_MENU,
            mobile: true,
            routesExclude: [ROUTES.LIVE, ROUTES.LIVE_SPORT, ROUTES.GAME, ROUTES.RESULTS, ROUTES.UPCOMING, ROUTES.PROMOTIONS, ROUTES.TODAY, ROUTES.SPECIAL, 'calendar.today'],
            params: {
                menuItems: carouselMenuItems,
                sliderConfig: {
                    slidesPerGroup: 2
                }
            }
        },
        // {
        //     module: MODULES.SEARCH,
        //     mobile: true,
        //     routesExclude: [ROUTES.LIVE, ROUTES.LIVE_SPORT, ROUTES.GAME, ROUTES.RESULTS],
        // },
    ],
    [POSITIONS.LEFT_SIDE]: [
        {
            module: MODULES.SEARCH,
            params: {
                type: 'dropdown-left'
            }
        },
        {
            module: MODULES.LEFT_MENU,
            params: {
                items: [
                    {
                        title: gettext(`Special today`),
                        link: 'special',
                        icon: 'down-arrow',
                        iconLeft: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.864211" fill-rule="evenodd" clip-rule="evenodd" d="M8.9957 18.4104L5.38191 18.1093L5.14395 14.4908L2.80151 11.7226L5.19191 8.99566L5.49298 5.38187L9.11148 5.14391L11.8797 2.80147L14.6066 5.19187L18.2204 5.49294L18.4584 9.11143L20.8008 11.8797L18.4104 14.6066L18.1094 18.2204L14.4909 18.4583L11.7226 20.8008L8.9957 18.4104Z" fill="#F5BE18"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 14L9.64886 15.2361L10.0979 12.618L8.19577 10.7639L10.8244 10.382L12 8L13.1756 10.382L15.8042 10.7639L13.9021 12.618L14.3511 15.2361L12 14Z" fill="white"/>
                        </svg>
                        `
                    },
                    {
                        icon: 'star',
                        title: gettext('My Favorites'),
                        link: 'favourite.matches'
                    }
                ]
            }
        },
        {
            module: MODULES.SPORTS_MENU_TABS,
            params: {
                minSportCount: 999
            }
        }
    ],
    [POSITIONS.RIGHT_SIDE]: [
        {
            module: MODULES.WIDGET_GAME_MINI_TRACKER,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live'],
            params: {
                liveTrackerTheme: EnumBetradarWidgetTheme.default
            }
        },
        {
            module: MODULES.BET_SLIP_WITH_OPEN_BETS,
            params: {
                inputType: 'buttons',
                template: 'v2',
                emptyIcon: 'empty-betslip',
                showTabs: true,
                hideCloseButton: true,
                openBetsAsLink: true,
                hasOpenBetsLink: false,
                showCount: true,
                resetAfterBet: true,
                showUserBalance: true,
            }
        },
        {
            module: MODULES.WIDGET_MATCH_STATS,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live'],
            params: {
                theme: EnumBetradarWidgetTheme.default
            }
        },
        {
            module: MODULES.BANNERS,
            params: {
                position: 'right'
            }
        },
        {
            module: MODULES.BET_ACCUMULATORS,
            params: {
                swiperConfig: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 5,
                }
            }
        },
        {
            module: MODULES.BIG_WINS,
            params: {
                daysOffset: 60
            }
        },
    ],
    ['GAMES_BY_DAYS_0'/*POSITIONS.GAMES_BY_DAYS_0*/]: [
        {
            module: MODULES.BANNERS,
            params: {
                className: 'sb-banners_promo-match',
                type: EnumBannerType.cards,
                // type: 'cards:v2', //EnumBannerType.cards,
                position: 'games_by_days_0',
                showCounter: true
            }
        }
    ],
    ['GAMES_BY_DAYS_1'/*POSITIONS.GAMES_BY_DAYS_0*/]: [
        {
            module: MODULES.TOP_LEAGUES_SLIDER
        },
    ],
    [POSITIONS.MAIN_TOP]: [

        {
            module: MODULES.CAROUSEL_MENU,
            desktop: true,
            routesExclude: ['live.events', 'live.events.sport', 'profile.history', 'profile.open-bets', 'profile.bonuses', ROUTES.GAME, ROUTES.RESULTS, 'special', ROUTES.UPCOMING, 'promotions', 'calendar.today'],
            params: {
                menuItems: carouselMenuItems,
                sliderConfig: {
                    slidesPerGroup: 2
                }
            }
        },
        // main page
        {
            module: MODULES.BANNERS,
            params: {
                // className: 'sb-banners_top-events',
                // preHTML: `
                //     <div class="sb-banners__promo">
                //         <h3>Tap. Play. Win. Always get best odds<br>from our team</h3>
                //         <h4>Top events</h4>
                //         <div class="sb-banners__circle-wrapper">
                //             <div class="sb-banners__circle sb-banners__circle_1"></div>
                //             <div class="sb-banners__circle sb-banners__circle_2"></div>
                //             <div class="sb-banners__circle sb-banners__circle_3"></div>
                //             <div class="sb-banners__circle sb-banners__circle_4"></div>
                //         </div>
                //     </div>
                // `,
                type: EnumBannerType.cards,
                position: EnumBannerPosition.main_page_top
            },
            routes: ['home', 'promotions']
        },
        {
            module: MODULES.BET_ACCUMULATORS,
            routes: ['promotions'],
        },
        {
            module: MODULES.HIGHLIGHTS,
            routes: ['special'],
        },
        {
            module: MODULES.GAMES_BY_DAYS,
            routes: ['home'],
        },
        // {
        //     module: MODULES.HOME_EVENTS,
        //     routes: ['home'],
        //     params: {
        //         hideIcons: false
        //     },
        //     paramsMobile: {
        //         hideIcons: false
        //     }
        // },
        // {
        //     module: MODULES.SUPER_TOP,
        //     routes: ['home']
        // },
        // otherwise
        // {
        //     module: MODULES.BREADCRUMBS,
        //     desktop: true,
        //     params: {
        //         showReturnBackBtn: false
        //     },
        //     routesExclude: ['home', 'profile.history', 'profile.open-bets', 'matches.sport.category.tournament.game']
        // },
        {
            module: MODULES.PAGE_HEADER,
            routes: [
                ROUTES.SPORT,
                ROUTES.SPORT_ALL_TOURNAMENTS,
                ROUTES.SPORT_TOP_TOURNAMENTS,
                ROUTES.SPORT_DAILY,
                ROUTES.SPORT_TODAY,
                ROUTES.SPORT_OUTRIGHTS,
                ROUTES.SPORT_LIVE,
                ROUTES.SPORT_ALL_TOURNAMENTS_COUPON,
                ROUTES.CATEGORY,
                ROUTES.TOURNAMENT,
                // ROUTES.LIVE,
                // ROUTES.LIVE_SPORT,
            ]
        },
        {
            module: MODULES.GAME_HEADER_V2,
            
            routes: [ROUTES.GAME, ROUTES.GAME_LIVE],
            params: {
                _showTrackerWidget: true
            }
        },
        {
            module: MODULES.GAME_TIPS,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live'],
        },
    ],
    [POSITIONS.MOBILE_RIGHT_PANEL]: [
        {
            module: MODULES.BET_SLIP_WITH_OPEN_BETS,
            params: {
                inputType: 'buttons',
                template: 'v2',
                emptyIcon: 'empty-betslip',
                showTabs: true,
                hideCloseButton: false,
                openBetsAsLink: true,
                hasOpenBetsLink: false,
                showCount: true,
                resetAfterBet: true,
                showUserBalance: true,
            }
        },
    ],
    [POSITIONS.MOBILE_FLOAT_BOTTOM]: [
        // {
        //     module: MODULES.FLOAT_BOTTOM_MENU
        // }
    ]
};


