import { EnumMobileMenuType } from "@egamings/sportsbook-core/sportsbook-common";

export const configDark: any = {
    externalComponents: {
        mobileMenu: {
            type: EnumMobileMenuType.coupon,
            styles: {
                background:'#111',
                borderColor:'#fff',
                iconColor:'#fff',
                counterBackground:'#111',
                counterColor:'#fff'
            }
        },
        notification: {
            styles: {
                background: "#222"
            }
        }
    },
};
