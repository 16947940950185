import { IModulesConfig, POSITIONS, MODULES } from '@egamings/sportsbook-core/sportsbook-module-system';

import {
    EnumBannerType,
    EnumBannerPosition,
} from '@egamings/sportsbook-core/sportsbook-banners-components';

import {
    EnumMenuType,
    EMobileMainMenuItemTriggers,
    EMobileMainMenuItemType,
    EnumMainMenuItemType,
    EnumMainMenuWidgetType,
    EMobileMainMenuV2ItemTriggers,
} from '@egamings/sportsbook-core/sportsbook-menu-components';

import { Sport } from '@egamings/brsdk';

const sportListButtons: any[] = [
    {
        icon: 'sports-livegames',
        title: gettext('Live now'),
        sref: 'live.events',
        count: function (sports: Sport[]): number {
            return sports.reduce(function (acc: number, s: Sport) {
                acc += s.getLiveGameCount();
                return acc;
            }, 0);
        }
    },
    {
        icon: 'sports-all-games',
        title: gettext('All games'),
        sref: 'home',
        count: function (sports: Sport[]): number {
            return sports.reduce(function (acc: number, s: Sport) {
                acc += s.getTotalGameCount() || (s.getSeasonCount() + s.getTournamentCount());
                return acc;
            }, 0);
        }
    },
    {
        icon: 'sports-favorites',
        title: gettext('Favorites'),
        sref: 'favourite.matches'
        // count @TODO
    }
];

export const modulesConfig: IModulesConfig = {
    [POSITIONS.HEADER]: [
        {
            module: MODULES.MAIN_MENU,
            params: {
                items: {
                    left: [
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Home'),
                            sref: 'home'
                        },
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Upcoming'),
                            sref: 'calendar.upcoming'
                        },
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Results'),
                            sref: 'results'
                        },
                    ],
                    right: [
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Open Bets'),
                            sref: 'profile.open-bets',
                            authroized: true,
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.delimiter,
                            authroized: true,
                        },
                        {
                            type: EnumMainMenuItemType.link,
                            title: gettext('Betting History'),
                            sref: 'profile.history',
                            authroized: true,
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.delimiter
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.oddsDropDown,
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.delimiter,
                        },
                        {
                            type: EnumMainMenuItemType.widget,
                            widget: EnumMainMenuWidgetType.gmtDropDown,
                        }
                    ]
                }
            }
        },
        {
            module: MODULES.MOBILE_MAIN_MENU_V2,
            desktop: false,
            mobile: true,
        },
    ],
    [POSITIONS.LEFT_SIDE]: [
        {
            module: MODULES.SEARCH,
            params: {
                type: 'dropdown-left'
            }
        },
        {
            module: MODULES.SPORTS_MENU_TABS,
            params: {
                minSportCount: 999,
                showTabs: ['cyber'],
                buttons: sportListButtons
            }
        }
    ],
    [POSITIONS.RIGHT_SIDE]: [
        {
            module: MODULES.BET_SLIP,
            params: {
                resetAfterBet: true,
                showUserBalance: true,
                betSlipItem: {
                    btnClearIcon: 'close'
                }
            }
        },
        {
            module: MODULES.WIDGET_MATCH_STATS,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live']
        },
        {
            module: MODULES.BANNERS,
            params: {
                position: 'right'
            }
        },
    ],
    [POSITIONS.MAIN_TOP]: [
        // main page
        {
            module: MODULES.BREADCRUMBS,
            mobile: true,
            paramsMobile: {
                showReturnBackBtn: true
            },
            routesExclude: ['home', 'profile.history', 'profile.open-bets', 'profile.bonuses']
        },
        {
            module: MODULES.BANNERS,
            params: {
                className: '',
                type: 'esports',
                position: EnumBannerPosition.main_page_top
            },
            routes: ['home']
        },
        {
            module: MODULES.HIGHLIGHTS,
            routes: ['special'],
        },
        {
            module: MODULES.GAMES_BY_TIME,
            routes: ['home'],
        },
        {
            module: MODULES.GAME_ESPORT_HEADER,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live'],
        },
    ],
    [POSITIONS.MOBILE_RIGHT_PANEL]: [
        {
            module: MODULES.BET_SLIP,
            params: {
                resetAfterBet: true,
                showUserBalance: true,
            }
        },
    ],
    [POSITIONS.MOBILE_FLOAT_BOTTOM]: [
        // {
        //     module: MODULES.FLOAT_BOTTOM_MENU
        // }
    ]
};
