import { Component, OnInit, Renderer2, ViewEncapsulation, ChangeDetectorRef, RendererStyleFlags2 } from '@angular/core';
import { FloatService, GoogleAnalyticsService, IFramePosition, IframeService, MessageEventData, MessageEvents, StateProvider } from '@egamings/sportsbook-core/sportsbook-common';
import { TranslateService } from '@ngx-translate/core';
import debounce from 'lodash-es/debounce';
import { UserService, ConfigService, SportsbookCoreProvider, SportsbookCoreState, BrowserProvider, DateService } from '@egamings/sportsbook-core';
import { SiteConfig } from './config/site.config';
import { interval } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    public title: string = 'app';
    public ready: boolean = false;
    public error: string = '';
    public maintenance: boolean = false;
    public maintenanceFrom: number;
    public maintenanceTo: number;
    public sessionError: boolean = false;
    public initError: boolean = false;
    protected mobile = false;
    protected framePosition?: IFramePosition;
    protected currentVisibility: boolean = true;
    protected sportsbookActivityTimeout: any;

    constructor(
        private readonly browser: BrowserProvider,
        private readonly stateProvider: StateProvider,
        private readonly configService: ConfigService,
        public readonly floatService: FloatService,
        private readonly userService: UserService,
        private readonly cdr: ChangeDetectorRef,
        private readonly renderer: Renderer2,
        private readonly iframeService: IframeService,
        private readonly translate: TranslateService,
        private readonly ga: GoogleAnalyticsService,
        private readonly coreProvider: SportsbookCoreProvider,
        private readonly siteConfig: SiteConfig,
        private readonly dateService: DateService,
    ) {
        if (this.siteConfig.isPrefetching()) {
            return;
        }

        this.translate.setDefaultLang('en');
        this.translate.use(this.configService.getLanguage());
        this.dateService.setLocale(this.configService.getLanguage());

        this.renderer.addClass(document.documentElement, `sb-lang-${this.configService.getLanguage()}`);

        if (browser.mobile) {
            this.mobile = true;
        }

        this.mobileClasses();

        this.ga.start();

        // @TODO
        const customRoutes = this.siteConfig.getCustomRoutes();
        if (customRoutes.length) {
            customRoutes.forEach(r => {
                try {
                    this.stateProvider.addRoute(r);
                } catch (e) {
                    console.error(e)
                }
            });
        }

        this.stateProvider.start();
    }

    ngOnInit() {
        if (this.siteConfig.isPrefetching()) {
            return;
        }

        try {
            this.coreProvider.getState().subscribe(state => {
                switch (state) {
                    case SportsbookCoreState.ERROR:
                        this.hidePreloader();
                        this.error = this.coreProvider.getLastError() ? this.coreProvider.getLastError().message : 'Error';
                        this.initError = true;
                        this.cdr.detectChanges();
                        break;

                    case SportsbookCoreState.READY:
                        if (!this.ready) {
                            this.hidePreloader();
                            this.ready = true;
                            this.cdr.detectChanges();
                            this.iframeService.sendEventPageLoaded();
                        }
                        break;

                    case SportsbookCoreState.ENDED:
                        this.sessionError = true;
                        this.cdr.detectChanges();
                        this.renderer.setStyle(
                            document.querySelector('.app-wrapper'),
                            'max-height',
                            `${window.outerHeight}px`,
                            RendererStyleFlags2.Important,
                        );
                        this.iframeService.sendEventScrollToTop();
                        break;
                }
            });
            this.coreProvider.bootstrap();

            this.startMaintenance();
        } catch (e) {
            console.error(e);
        }

        this.userService.getOddsFormat().subscribe(_ => {
            this.refreshPage();
        });

        this.userService.getTimeZone().subscribe(_ => {
            this.refreshPage();
        });

        this.userService
            .getMarketTemplate()
            .pipe(distinctUntilChanged())
            .subscribe(_ => {
                this.updateMarketsView();
                this.refreshPage();
            });

        const checkMobileThrottle = debounce(() => this.checkMobile(), 500);
        window.addEventListener('resize', () => checkMobileThrottle());

        this.renderer.addClass(document.documentElement, 'sb-root');

        const deviceInfo = this.browser.getDeviceInfo();

        if (deviceInfo.browser) {
            this.renderer.addClass(document.documentElement, 'sb-browser-' + deviceInfo.browser.toLowerCase());
        }

        if (deviceInfo.os) {
            this.renderer.addClass(document.documentElement, 'sb-os-' + deviceInfo.os.toLowerCase());
        }

        if (this.siteConfig.isLayoutScrollable()) {
            if (this.iframeService.isIframe()) {
                let lastFrameHeight: number;
                let lastFrameTop: number;

                this.iframeService.getFramePosition().subscribe(position => {
                    if (!position) return;

                    if (lastFrameHeight === position.top && lastFrameHeight === position.wHeight) {
                        return;
                    }

                    this.framePosition = position;
                    this.checkLayoutScrollable();
                    this.cdr.detectChanges();
                });
            } else {
                this.renderer.addClass(document.documentElement, 'sb-layout-scrollable');
            }
        }

        if (this.iframeService.isIframe()) {
            let lastVisibility: boolean = true;

            this.iframeService.onMessage().subscribe({
                next: (msg: MessageEventData) => {
                    switch (msg.eventType) {
                        case MessageEvents.setTheme:
                            this.siteConfig.setThemeConfig(msg.eventData);
                            break;

                        case MessageEvents.enableCustomCss: {
                            const result = this.siteConfig.enableCustomCSS();

                            if (msg.mid) {
                                this.iframeService.sendResponse(null, {
                                    result
                                }, msg.mid);
                            }
                            break;
                        }

                        case MessageEvents.disableCustomCss: {
                            const result = this.siteConfig.disableCustomCSS();

                            if (msg.mid) {
                                this.iframeService.sendResponse(null, {
                                    result
                                }, msg.mid);
                            }
                            break;
                        }

                        case MessageEvents.addRootClass: {
                            this.renderer.addClass(document.documentElement, msg.eventData.className);

                            if (msg.mid) {
                                this.iframeService.sendResponse(null, {
                                    result: true
                                }, msg.mid);
                            }
                            break;
                        }

                        case MessageEvents.removeRootClass: {
                            this.renderer.removeClass(document.documentElement, msg.eventData.className);

                            if (msg.mid) {
                                this.iframeService.sendResponse(null, {
                                    result: true
                                }, msg.mid);
                            }
                            break;
                        }

                        case MessageEvents.visibility: {
                            lastVisibility = msg.eventData.visible;

                            if (this.currentVisibility !== lastVisibility) {
                                if (!lastVisibility && !this.sportsbookActivityTimeout) {
                                    this.sportsbookActivityTimeout = setTimeout(() => {
                                        this.changeSportsbookActivity(lastVisibility);
                                        this.sportsbookActivityTimeout = 0;
                                    }, 30000);
                                } else if (lastVisibility) {
                                    clearTimeout(this.sportsbookActivityTimeout);
                                    this.changeSportsbookActivity(lastVisibility);
                                }
                            }
                            break;
                        }
                    }
                }
            });
        }
    }

    private startMaintenance(): void {
        setTimeout(() => {
            interval(10000)
                .subscribe(() => this.checkMaintenance());

            this.checkMaintenance();
        }, 0);
    }

    private async checkMaintenance(): Promise<void> {
        try {
            const maintenance = await fetch('/static/maintenance.json', { cache: "no-store" }).then(r => r.json());
            const currentValue = this.maintenance;

            if (maintenance) {
                this.maintenance = maintenance.active;
                this.maintenanceFrom = maintenance.from;
                this.maintenanceTo = maintenance.to;

                if (this.maintenance) {
                    this.renderer.addClass(document.getElementById('sb-preloader'), 'sb-preloader-hidden');
                }
            } else {
                this.maintenance = false;
            }

            if (currentValue !== this.maintenance) {
                this.cdr.detectChanges();
            }
        } catch (e) { }
    }

    private changeSportsbookActivity(lastVisibility: boolean): void {
        if (lastVisibility === this.currentVisibility) {
            return;
        }

        let executed: boolean = false;

        if (lastVisibility) {
            executed = this.coreProvider.resume();
        } else {
            executed = this.coreProvider.pause();
        }

        if (executed) {
            this.currentVisibility = lastVisibility;
        }
    }

    private updateMarketsView(): void {
        const marketsTemplate = this.userService.getMarketTemplateValue();

        this.siteConfig.updateMarketsView(marketsTemplate, this.mobile);
    }

    private refreshPage() {
        if (this.ready) {
            this.ready = false;
            this.cdr.detectChanges();
            this.checkLayoutScrollable();
            this.ready = true;
            this.cdr.detectChanges();
            this.cdr.markForCheck();
        }
    }

    private checkMobile() {
        if (this.mobile !== this.browser.mobile) {
            this.mobile = this.browser.mobile;
            this.updateMarketsView();
            this.mobileClasses();
            this.refreshPage();
        }
    }

    private mobileClasses() {
        if (this.mobile) {
            this.renderer.removeClass(document.documentElement, 'app-desktop');
            this.renderer.removeClass(document.documentElement, 'sb-context-desktop');

            this.renderer.addClass(document.documentElement, 'app-mobile');
            this.renderer.addClass(document.documentElement, 'sb-context-mobile');
        } else {
            this.renderer.removeClass(document.documentElement, 'app-mobile');
            this.renderer.removeClass(document.documentElement, 'sb-context-mobile');

            this.renderer.addClass(document.documentElement, 'app-desktop');
            this.renderer.addClass(document.documentElement, 'sb-context-desktop');
        }
    }

    private checkLayoutScrollable() {
        if (this.isLayoutScrollable()) {
            this.renderer.addClass(document.documentElement, 'sb-layout-scrollable');

            if (this.iframeService.isIframe() && this.framePosition) {
                this.iframeService.setFrameStyles({ minHeight: (this.framePosition.height - this.framePosition.top) + 'px' });
            }
        } else {
            this.renderer.removeClass(document.documentElement, 'sb-layout-scrollable');
            this.iframeService.setFrameStyles({ minHeight: '100vh' });
        }
    }

    private hidePreloader(): void {
        this.renderer.addClass(document.getElementById('sb-preloader'), 'sb-preloader-hidden');
    }

    public isLayoutScrollable(): boolean {
        if (!this.siteConfig.isLayoutScrollable() || this.mobile) {
            return false;
        }

        if (!this.iframeService.isIframe()) {
            return true;
        }

        if (!this.framePosition) {
            return false;
        }

        return ((this.framePosition.wHeight - this.framePosition.top) / this.framePosition.wHeight) >= 0.8;
    }

    public isLeftSideSubEnabled(): boolean {
        return this.siteConfig.isLeftSideSubEnabled();
    }
}
