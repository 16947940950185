import { IMarketGroup, ISettingsConfigMarkets } from "@egamings/brsdk";

export enum EMarketGroupConfigType {
    ALL = 'all',
    LIVE = 'live',
    PREMATCH = 'prematch',
}

export type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};

export interface IMarketGroupConfigExtended extends IMarketGroup {
    configType: EMarketGroupConfigType;
    weight: PartialRecord<EMarketGroupConfigType, number>;
}

export interface ISettingsConfigMarketsExtended extends ISettingsConfigMarkets {
    marketGroups: IMarketGroupConfigExtended[];
}
