import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import get from 'lodash-es/get';
import set from 'lodash-es/set';
import has from 'lodash-es/has';
import mergeWith from 'lodash-es/mergeWith';
import merge from 'lodash-es/merge';
import isArray from 'lodash-es/isArray';
import isObject from 'lodash-es/isObject';
import isString from 'lodash-es/isString';
import isFunction from 'lodash-es/isFunction';
import intersection from 'lodash-es/intersection';
import values from 'lodash-es/values';
import uniq from 'lodash-es/uniq';
import { UIRouter } from '@uirouter/angular';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import {
    Config,
    EMarketConfigType,
    EMarketGroupType,
    EMarketsCacheGroup,
    IMarketGroup,
    IMarketGroupSportSort,
    ISettingsConfigMarkets,
} from '@egamings/brsdk';
import { environment } from 'src/environments/environment';
import { modulesConfig } from './modules.config';
import { IModulesConfig, ModuleSystem, IModulesConfigItem } from '@egamings/sportsbook-core/sportsbook-module-system';
import { ISportsbookCommonParams, EnumMobileMenuType, EnumSportPageTabs, ICustomState } from '@egamings/sportsbook-core/sportsbook-common';
import { BetConfig } from './bet.config';
import { ICoreConfig, DateFormats } from '@egamings/sportsbook-core';
import { configV2, configEsport, configDark, configPurple, configGold } from './themes';
import dayjs from 'dayjs';
import { EMarketGroupConfigType, IMarketGroupConfigExtended, ISettingsConfigMarketsExtended, PartialRecord, allGroups } from './markets';
import { TranslateService } from '@ngx-translate/core';

const paramsToConfig = {
    UserId: 'user.id',
    AuthToken: 'user.authToken',
    siteId: 'site.id',
    lang: 'site.language',
};

const languageMap = {
    rus: 'ru',
    ury: 'us',
    br: 'pt-br',
    cn: 'zh',
    'zh-cn': 'zh',
    'zh-hans': 'zh',
    srl: 'sr',
};

function mergeArray(item1, item2) {
    if (isArray(item1) || isArray(item2)) {
        return item2;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SiteConfig {
    public useMock = false;
    public sdkConfig: Config;
    private prefetching: boolean = false;

    constructor(
        private readonly router: UIRouter,
        private readonly translateService: TranslateService,
    ) {
        let params = this.router.urlService.search();
        const hash = this.router.urlService.hash();

        if (hash !== '') {
            try {
                const hashTree: UrlTree = (new DefaultUrlSerializer).parse(hash);
                params = { ...params, ...hashTree.queryParams };
            } catch (err) {
                console.error(err);
            }
        }

        for (let param in paramsToConfig) {
            if (has(params, param)) {
                if (param === 'siteId') {
                    parseInt(set(this.config, paramsToConfig[param], params[param]));
                } else {
                    set(this.config, paramsToConfig[param], params[param]);
                }
            }
        }

        if (params.is_prefetching) {
            this.prefetching = true;
        }
    }

    public config: any = {
        theme: 'default',
        modules: modulesConfig,
        connection: {
            url: environment.connection.url,
            streamingApiUrl: environment.connection.streamingApiUrl,
            sportsBookApiUrl: environment.connection.sportsBookApiUrl,
        },
        failoverConnection: environment.failoverConnection ? {
            url: environment.failoverConnection.url,
            streamingApiUrl: environment.failoverConnection.streamingApiUrl,
            sportsBookApiUrl: environment.failoverConnection.sportsBookApiUrl,
        } : null,
        widgetUrl: 'https://widgets.sir.sportradar.com/b40a3fe1de1a5fb73b93aa7cee822619/widgetloader',
        staticServerUrl: 'https://static-sportsbet.esportings.com',
        statisticsServerUrl: 'https://stats-sportsbet.esportings.com/softgamings',
        layout: {
            scrollable: true,
            leftSideSub: false,
        },
        user: {
            id: '',
            authToken: '',
            /*defaultOddsFormats: {
                all: ODDS_FORMAT.us,
            }*/
            /*defaultCurrencies: {
                all: 'USD',
                byLanguage: {
                    br: 'BRL'
                }
            }*/
        },
        site: {
            id: 1,
            language: 'en'
        },
        betting: {
            systemMultiply: true,
            mode: 2,
            loadUserBetSlip: true,
            steps: BetConfig.get('steps'),
            history: BetConfig.get('history'),
            /*amountRounding: {
                all: 100,
                EUR: 200,
            },
            disabledBetTypes: ['system'],*/
        },
        print: false,
        searchPeriod: 7,
        searchPeriodCategory: 90,
        searchPeriodTournament: 90,
        templates: {
            components: {
                sport: {
                    template: 'default:sport',
                    params: {
                    }
                },
                category: {
                    template: 'default:category',
                    params: {
                    }
                },
                tournament: {
                    template: 'default:tournament',
                    params: {
                    }
                },
                live: {
                    template: 'default:live',
                    params: {
                    }
                },
                topLeagues: {
                    template: 'default:top-leagues',
                    params: {
                    }
                },
                coupon: {
                    template: 'default:coupon',
                    params: {
                    }
                },
                gamesPreview: {
                    template: 'default:games-preview',
                    params: {
                    }
                },
                upcoming: {
                    template: 'default:upcoming',
                    params: {
                    }
                },
                highlights: {
                    template: 'default:highlights',
                    params: {
                    }
                },
                allLeagues: {
                    template: 'default',
                    params: {
                    }
                }
            }
        },
        dateFormat: {
            [DateFormats.sportToday]: 'HH:mm',
            [DateFormats.sportOther]: 'DD MMMM',
            [DateFormats.sportMobile]: 'DD/MM HH:mm',
            [DateFormats.betHistory]: 'DD MMMM HH:mm',
            [DateFormats.todayAndDate]: '[Today] DD MM',
            [DateFormats.today]: '[Today]',
            [DateFormats.dayMonth]: 'DD MM',
            [DateFormats.hoursMinutes]: 'HH:mm',
            [DateFormats.sportResults]: 'DD MMMM HH:mm',
            [DateFormats.sportResultsShort]: 'DD MMM HH:mm',
            [DateFormats.calendarDayOfWeek]: 'dddd',
            [DateFormats.calendarDayOfMonth]: {
                all: 'DD MMMM',
                ja: 'MMMM DD 日'
            },
            [DateFormats.gameStartTime]: 'L LT',
            [DateFormats.topGames]: 'L LT'
        },
        outrights: true,
        sport: {
            list: {
                seasonsCounter: true,
                tournamentsCounter: true
            },
            minSportCount: 10,
        },
        games: {
            sportIcons: false
        },
        category: {
            list: {
                sortByCountry: true
            }
        },
        calendar: {
            days: 7
        },
        results: {
            days: 7
        },
        home: {
            prematch: {
                sort: 'byTime',
                limit: 10
            },
            live: {
                sort: 'byTime',
                limit: 12
            },
        },
        marketGroupsType: 'default', // table
        marketGroups: allGroups,
        marketGroupsTable: [
            {
                id: '1x2',
                name: gettext('Results'),
                type: EMarketGroupType.results,
                externalIds: [1, 4, 186, 219, 251, 340, 406],
                maxRowItems: 3,
                position: 'right',
                canShowTeamNumbers: true,
                useAsDefault: true,
            },
            {
                id: 'double-chance',
                name: gettext('Double Chance'),
                type: EMarketGroupType.doubleChance,
                externalIds: [10],
                maxRowItems: 3,
                position: 'right',
                canShowTeamNumbers: true,
                useAsDefault: true
            },
            {
                id: 'total',
                name: gettext('Total'),
                type: EMarketGroupType.totals,
                externalIds: [18, 189, 225, 238, 258],
                maxRowItems: 2,
                position: 'right',
                canShowTeamNumbers: false,
                useAsDefault: true
            },
            {
                id: 'handicap',
                name: gettext('Handicap'),
                type: EMarketGroupType.handicap,
                externalIds: [16, 187, 223, 237, 256],
                maxRowItems: 2,
                position: 'right',
                canShowTeamNumbers: false,
                useAsDefault: true
            },
            {
                id: 'score',
                name: gettext('Score'),
                type: EMarketGroupType.scores,
                externalIds: [45],
                maxRowItems: 2,
                position: 'right',
                canShowTeamNumbers: false,
                useAsDefault: false
            }
        ],
        marketsCacheGroups: {
            [EMarketGroupConfigType.ALL]: EMarketsCacheGroup.main,
        },
        marketTags: [
            {
                name: gettext('All Markets'),
                tags: []
            },
            {
                name: gettext('Main'),
                tags: ['main']
            },
            {
                name: gettext('Bet Builder'),
                tags: ['betbuilder'],
                icon: 'bet-builder'
            },
            {
                name: gettext('Goals'),
                tags: ['goals']
            },
            {
                name: gettext('Halves'),
                tags: ['halves']
            },
            {
                name: gettext('Corners'),
                tags: ['corners']
            },
            {
                name: gettext('Players'),
                tags: ['players']
            },
            {
                name: gettext('Cards'),
                tags: ['cards']
            },
            {
                name: gettext('Set'),
                tags: ['set']
            },
            {
                name: gettext('Quarter'),
                tags: ['quarter']
            },
            {
                name: gettext('Period'),
                tags: ['period']
            },
            {
                name: gettext('Combo'),
                tags: ['combo']
            },
            {
                name: gettext('Point'),
                tags: ['point']
            },
            {
                name: gettext('Special'),
                tags: ['special']
            },
            {
                name: gettext('Minimarket'),
                tags: ['minimarket']
            },
        ],
        topGames: {
            target: 'games',
            tags: ['top'],
            entities: ['categories', 'tournaments'],
            limitBySport: 4,
            sports: ['soccer', 'tennis', 'ice-hockey'],
            startDay: 0,
            endDay: 7
        },
        topTournaments: {
            target: 'tournaments',
            tags: ['top'],
            entities: ['categories', 'tournaments'],
            limit: 10,
            sports: [], //['basketball', 'soccer', 'tennis', 'ice-hockey'],
            startDay: 0,
            endDay: 7,
            showIcons: true
        },
        liveGames: {
            showGames: true,
            showTournaments: 5,
            mobile: {
                showGames: false,
                showTournaments: 5,
            }
        },
        // sportsSlider: {
        //     // boolean or Object
        //     hideIcons: {
        //         onlyOnHomePage: true
        //     }
        // },
        sportPage: {
            tabsOrder: [
                EnumSportPageTabs.liveGames,
                EnumSportPageTabs.dailyMathList,
                EnumSportPageTabs.topLeagues,
                EnumSportPageTabs.outrights,
                EnumSportPageTabs.allLeagues
            ]
        },
        externalComponents: {
            mobileMenu: {
                type: EnumMobileMenuType.coupon,
                styles: {
                    background: '#495b8d',
                    borderColor: '#fff',
                    iconColor: '#fff',
                    counterBackground: '#000',
                    counterColor: '#fff'
                }
            },
            notification: {
                styles: {
                    background: '#1b1f29',
                    color: '#fff',
                    errorIconColor: '#D96969',
                    successIconColor: '#45C195',
                    closeButtonColor: '#fff',
                    borderRadius: '0px',
                }
            }
        },
        sportIcons: {
            set: 'set1'
        },
        svgIcons: {
            mapping: {
            }
        },
        marketWithThreeOutcomesIds: [
            1, 7, 8, 9, 10, 14, 15, 52, 53, 54, 60, 61, 62, 63, 65, 83, 84, 85, 87, 102, 103, 105, 106, 108, 109,
            113, 115, 119, 125, 136, 137, 149, 150, 162, 163, 164, 173, 174, 175, 565, 566, 572, 573, 579, 580,
            586, 591, 596, 601, 602, 800, 892, 209, 217, 235, 290, 293, 297, 301, 563, 1057, 609, 610, 611, 903,
            905, 753, 754, 253, 254, 262, 266, 270, 271, 274, 279, 287, 738, 743, 1053, 317, 347, 351, 645, 646,
            647, 648, 656, 680, 681, 686, 687, 688, 743, 710, 711, 712, 713, 714, 835, 836, 837, 838, 839, 840, 841,
            842, 843, 844, 845, 846, 847, 365, 370, 377, 378, 381, 382, 387, 1036, 1037, 114, 634, 407, 408, 420,
            443, 444, 445, 457, 458, 463, 529, 472, 473, 474, 476, 482, 483, 485, 491, 497, 498, 326, 334
        ],
        /*,
        sortFunctions: {
            categories: (item1: Category, item2: Category) => {
                const
                    name1 = (item1.hasField('getName') ? item1.getName() : '') || '',
                    name2 = (item2.hasField('getName') ? item2.getName() : '') || '';

                return name1.localeCompare(name2);
            }
        }*/
        customTranslations: {},
        customRoutes: [],
        // restrictions: {
        //     competitorLogos: {
        //         tournamentId: ['66729']
        //     }
        // }
    };

    public isPrefetching(): boolean {
        return this.prefetching;
    }

    public async init() {
        let params = this.router.urlService.search();
        const hash = this.router.urlService.hash();

        if (hash !== '') {
            try {
                const hashTree: UrlTree = (new DefaultUrlSerializer).parse(hash);
                params = { ...params, ...hashTree.queryParams };
            } catch (err) {
                console.error(err);
            }
        }

        if (window['sbPreLoader'] || params.config) {
            try {
                let promise;

                if (window['sbPreLoader']) {
                    promise = new Promise<void>((resolve) => window['sbPreLoader'].onReady(() => resolve()));
                } else {
                    const el = document.createElement('script');
                    el.type = 'text/javascript';
                    el.src = decodeURIComponent(params.config);
                    document.head.appendChild(el);
                    promise = new Promise<void>((resolve, reject) => {
                        el.onload = () => resolve();
                        el.onerror = e => reject(e);
                    });
                }

                await promise;
            } catch (e) {
                console.warn(e)
            }
        }

        const themeElement = document.getElementById('sportsbook-theme-css');
        const theme = themeElement && themeElement.dataset ? themeElement.dataset.theme : null;

        if (theme) {
            params.theme = theme;
        }

        this.setThemeConfig(params);

        if (isObject(window['SPORTSBOOK_CUSTOM_CONFIG'])) {
            mergeWith(this.config, window['SPORTSBOOK_CUSTOM_CONFIG'], mergeArray);
        }

        if (isFunction(window['SPORTSBOOK_DYNAMIC_CONFIG'])) {
            const dynamicConfig = window['SPORTSBOOK_DYNAMIC_CONFIG'].call(this, this.config);

            if (isObject(dynamicConfig)) {
                mergeWith(this.config, dynamicConfig, mergeArray);
            }
        }

        if (!window['sbPreLoader'] && params.css) {
            const el = document.createElement('link');
            el.rel = 'stylesheet';
            el.href = decodeURIComponent(params.css);
            document.head.appendChild(el);
        }

        if (this.config.marketGroupsType === 'table') {
            this.config.marketGroups = this.config.marketGroupsTable;
        }
    }

    public get(configPath: string) {
        return get(this.config, configPath);
    }

    public set(paramName: string, paramValue: any): void {
        set(this.config, paramName, paramValue);
    }

    public getLanguage(): string {
        return languageMap[this.config.site.language] || this.config.site.language;
    }

    public getSportsbookCoreConfig(): ICoreConfig {
        return {
            connection: this.config.connection,
            failoverConnection: this.config.failoverConnection,
            widgets: {
                url: this.config.widgetUrl,
            },
            staticServerUrl: this.config.staticServerUrl,
            statisticsServerUrl: this.config.statisticsServerUrl,
            site: {
                siteId: this.config.site.id,
                language: this.getLanguage(),
            },
            user: {
                id: this.config.user.id,
                authToken: this.config.user.authToken,
                defaultCurrencies: this.config.user.defaultCurrencies || null,
                defaultOddsFormats: this.config.user.defaultOddsFormats || null,
                defaultMarketTemplate: this.config.user.defaultMarketTemplate || null,

            },
            print: this.config.print,
            settings: {
                markets: () => this.getMarketsConfig(),
                sortFunctions: this.config.sortFunctions ? this.config.sortFunctions : {},
                localizerFn: this.translateService.instant.bind(this.translateService),
            },
            externalSettings: window['SPORTSBOOK_CLIENT_SETTINGS'] || null,
            betting: {
                systemMultiply: this.config.betting.systemMultiply,
                minAmount: this.config.betting.minAmount || {},
            },
            outrights: this.config.outrights,
            betConfig: {
                steps: this.config.betting.steps,
                history: {
                    items: this.config.betting.history.items,
                    defaultDuration: this.config.betting.history.defaultDuration
                },
                mode: this.config.betting.mode,
                loadUserBetSlip: this.config.betting.loadUserBetSlip,
                amountRounding: this.config.betting.amountRounding,
                disabledBetTypes: this.config.betting.disabledBetTypes,
            },
            sport: {
                seasonsCounter: this.config.sport.list.seasonsCounter,
                tournamentsCounter: this.config.sport.list.tournamentsCounter,
            },
            dateFormat: this.config.dateFormat,
            restrictions: this.config.restrictions,
        };
    }

    public getSportsbookCommonConfig(): ISportsbookCommonParams {
        const { liveGames } = this.config;

        return {
            externalComponents: this.config.externalComponents,
            templates: this.config.templates,

            components: {
                topGames: this.config.topGames,
                topTournaments: this.config.topTournaments,
                liveGames: {
                    showGames: liveGames.showGames,
                    showTournaments: liveGames.showTournaments,
                    hideIcons: liveGames.hideIcons || false,
                    hideCount: liveGames.hideCount || false,
                    hideAllSports: liveGames.hideAllSports || false,
                    mobile: {
                        showGames: liveGames.mobile ? liveGames.mobile.showGames : liveGames.showGames,
                        showTournaments: liveGames.mobile ? liveGames.mobile.showTournaments : liveGames.showTournaments,
                        hideIcons: liveGames.mobile ? liveGames.mobile.hideIcons : false,
                        hideCount: liveGames.mobile ? liveGames.mobile.hideCount : false,
                        hideAllSports: liveGames.mobile ? liveGames.mobile.hideAllSports : false,
                    }
                },
                sportList: {
                    minSportCount: this.config.sport.minSportCount,
                    sortCategoryByCountry: this.config.category.list.sortByCountry,
                },
                weekCalendar: {
                    days: this.config.calendar.days,
                },
                svgIcon: {
                    sportIcons: {
                        set: this.config.sportIcons.set,
                    },
                    mapping: this.config.svgIcons.mapping,
                },
                competitions: {
                    sportIcons: this.config.games.sportIcons,
                },
            },
            pages: {
                sport: {
                    tabsOrderMobile: this.config.sportPage.tabsOrderMobile,
                    tabsOrder: this.config.sportPage.tabsOrder,
                },
                category: {
                    searchPeriodDays: this.config.searchPeriodCategory,
                },
                tournament: {
                    searchPeriodDays: this.config.searchPeriodTournament,
                },
                game: {
                    marketTags: this.config.marketTags,
                    marketsThreeInLine: this.config.marketWithThreeOutcomesIds,
                    marketsViewType: this.config.marketsViewType,
                }
            }
        };
    }

    /**
     * @TODO
     */
    public getDate(): dayjs.Dayjs {
        return dayjs();
    }

    public getModulesConfig(): IModulesConfig {
        return this.config.modules;
    }

    public getStatisticsUrl(): string {
        return this.config.statisticsServerUrl;
    }

    public moduleSystem(): ModuleSystem {
        return new ModuleSystem(this.getModulesConfig());
    }

    public setModulesParams(position: string, moduleId: string, params: any, routes: string[] = [], useMerge: boolean = true): void {
        const modules: IModulesConfigItem[] = this.getModulesConfigById(position, moduleId);

        for (const m of modules) {
            if (routes && routes.length && !intersection(m.routes, routes).length) {
                continue;
            }

            if (useMerge && m.params) {
                merge(m.params, params);
            } else {
                m.params = params;
            }
        }
    }

    public getModulesConfigByPosition(position: string): IModulesConfigItem[] {
        return this.getModulesConfig()[position] || [];
    }

    public getModulesConfigById(position: string, id: string): IModulesConfigItem[] {
        return this.getModulesConfigByPosition(position).filter(m => m.module === id);
    }

    public getBettingConfig(): any {
        return this.config.betting;
    }

    public getSiteId(): number {
        return this.get('site.id');
    }

    public getCustomTranslations(lang: string): { [index: string]: string } {
        return this.config.customTranslations ? this.config.customTranslations[lang] : null;
    }

    public getCustomRoutes(): ICustomState[] {
        return this.config.customRoutes || [];
    }

    public updateMarketsView(marketsTemplate: string, mobile: boolean = false) {
        if (mobile && this.config.marketGroupsMobile) {
            this.config.marketGroups = this.config.marketGroupsMobile;
            this.resetMarketsConfig();
            return;
        }

        if (!marketsTemplate) {
            return;
        }

        if (!(this.config.euroMarketGroups && this.config.asianMarketGroups)) {
            return;
        }

        const commonConfig = this.getSportsbookCommonConfig();
        for (let i in commonConfig.templates.components) {
            if (commonConfig.templates.components[i].params && commonConfig.templates.components[i].params.marketsTemplate) {
                commonConfig.templates.components[i].params.marketsTemplate = marketsTemplate;
            }
        }

        const marketGroups = marketsTemplate === 'asian' ? this.config.asianMarketGroups : this.config.euroMarketGroups;
        const cacheGroups = marketsTemplate === 'asian' ? this.config.asianMarketsCacheGroups : this.config.euroMarketsCacheGroups;
        this.config.marketGroups = marketGroups;
        this.config.marketsCacheGroups = cacheGroups;
        this.resetMarketsConfig();
    }

    public isLayoutScrollable(): boolean {
        return this.config.layout.scrollable;
    }

    public isLeftSideSubEnabled(): boolean {
        return this.config.layout.leftSideSub;
    }

    public setThemeConfig(params: any) {
        let theme;

        try {
            theme = window.localStorage.getItem('sb_theme');
        } catch (e) { }

        if (!theme) {
            theme = get(params, 'theme');
        }

        if (!theme && this.config.theme) {
            theme = this.config.theme;
        }

        let themeConfig;

        switch (theme) {
            case 'v2':
                themeConfig = configV2;
                break;

            case 'esport':
                themeConfig = configEsport;
                break;

            case 'dark':
                themeConfig = configDark;
                break;

            case 'purple':
                themeConfig = configPurple;
                break;

            case 'gold':
                themeConfig = configGold;
                break;
        }

        if (themeConfig) {
            for (let i in themeConfig) {
                this.config[i] = themeConfig[i];
            }
        }

        if (theme && isString(theme)) {
            const classList = document.querySelector('html').classList;

            classList.forEach(className => {
                if (className.includes('sb-theme-')) {
                    classList.remove(className);
                }
            });

            classList.add('sb-theme-' + theme);
        }

        this.config.theme = theme;
    }

    public enableCustomCSS(): boolean {
        const customCss: HTMLLinkElement = <HTMLLinkElement>document.getElementById('sportsbook-custom-css');

        if (!customCss) {
            return false;
        }

        customCss.rel = 'stylesheet';

        return true;
    }

    public disableCustomCSS(): boolean {
        const customCss: HTMLLinkElement = <HTMLLinkElement>document.getElementById('sportsbook-custom-css');

        if (!customCss) {
            return false;
        }

        customCss.rel = 'disabled';

        return true;
    }

    protected marketsConfig: ISettingsConfigMarkets[] = null;

    protected resetMarketsConfig(): void {
        this.marketsConfig = null;
    }

    protected getMarketsConfig(): ISettingsConfigMarkets[] {
        if (!this.marketsConfig) {
            const groups: IMarketGroupConfigExtended[] = this.config.marketGroups;
            const liveMarketGroups: IMarketGroupConfigExtended[] = [];
            const prematchMarketGroups: IMarketGroupConfigExtended[] = [];;

            for (const group of groups) {
                switch (group.configType) {
                    case EMarketGroupConfigType.PREMATCH:
                        prematchMarketGroups.push(group);
                        break;

                    case EMarketGroupConfigType.LIVE:
                        liveMarketGroups.push(group);
                        break;

                    default:
                        liveMarketGroups.push(group);
                        prematchMarketGroups.push(group);
                        break;
                }
            }

            const live: ISettingsConfigMarkets = {
                type: EMarketConfigType.LIVE,
                cacheGroup: this.config.marketsCacheGroups[EMarketGroupConfigType.LIVE]
                    ?? this.config.marketsCacheGroups[EMarketGroupConfigType.ALL],
                marketGroups: this.mergeMarketGroups(liveMarketGroups, EMarketGroupConfigType.LIVE),
            };
            const prematch: ISettingsConfigMarkets = {
                type: EMarketConfigType.PREMATCH,
                cacheGroup: this.config.marketsCacheGroups[EMarketGroupConfigType.PREMATCH]
                    ?? this.config.marketsCacheGroups[EMarketGroupConfigType.ALL],
                marketGroups: this.mergeMarketGroups(prematchMarketGroups, EMarketGroupConfigType.PREMATCH),
            };

            this.marketsConfig = [live, prematch];
        }

        return this.marketsConfig;
    }

    protected mergeMarketGroups(groups: IMarketGroupConfigExtended[], configType: EMarketGroupConfigType): IMarketGroup[] {
        const grouped: Record<string, IMarketGroup> = {};

        for (const g of groups) {
            const uid = this.marketGroupUid(g);

            if (grouped[uid]) {
                const gg = grouped[uid];

                gg.externalIds = gg.externalIds.concat(g.externalIds);

                if (g.excludeSportIds) {
                    gg.excludeSportIds = (gg.excludeSportIds ?? []).concat(g.excludeSportIds);
                }

                gg.includeSportIds = gg.includeSportIds.concat(g.includeSportIds ?? ['all']);

                if (g.sportSort) {
                    gg.sportSort = merge({}, gg.sportSort, g.sportSort);
                }

                if (g.includeSportIds && g.weight) {
                    gg.sportSort = g.includeSportIds.reduce((acc, id) => {
                        const w = g.weight[configType] ?? g.weight[EMarketGroupConfigType.ALL];

                        if (w !== undefined) {
                            acc[id] = w;
                        }

                        return acc;
                    }, gg.sportSort ?? {});
                } else if (
                    g.weight && (
                        g.weight[configType] !== undefined ||
                        g.weight[EMarketGroupConfigType.ALL] !== undefined
                    )
                ) {
                    gg.sportSort = gg.sportSort ?? {};
                    gg.sportSort.all = g.weight[configType] ?? g.weight[EMarketGroupConfigType.ALL];
                }
            } else {
                let sorts: IMarketGroupSportSort = g.sportSort;

                if (g.includeSportIds && g.weight) {
                    sorts = g.includeSportIds.reduce((acc, id) => {
                        const w = g.weight[configType] ?? g.weight[EMarketGroupConfigType.ALL];

                        if (w !== undefined) {
                            acc[id] = w;
                        }

                        return acc;
                    }, sorts ?? {});
                } else if (
                    g.weight &&
                    (
                        g.weight[configType] !== undefined ||
                        g.weight[EMarketGroupConfigType.ALL] !== undefined
                    )
                ) {
                    if (!sorts) {
                        sorts = {};
                    }

                    sorts.all = g.weight[configType] ?? g.weight[EMarketGroupConfigType.ALL];
                }

                grouped[uid] = {
                    id: g.id,
                    name: g.name,
                    type: g.type,
                    externalIds: g.externalIds.slice(0),
                    maxRowItems: g.maxRowItems,
                    canShowTeamNumbers: g.canShowTeamNumbers,
                    useAsDefault: g.useAsDefault,
                    excludeSportIds: g.excludeSportIds ? g.excludeSportIds.slice(0) : null,
                    includeSportIds: g.includeSportIds ? g.includeSportIds.slice(0) : ['all'],
                    sportSort: sorts,
                    outcomesTitles: g.outcomesTitles,
                    filter: g.filter,
                };
            }
        }

        const result = values(grouped).map(g => {
            g.externalIds = uniq(g.externalIds);
            g.excludeSportIds = g.excludeSportIds ? uniq(g.excludeSportIds) : g.excludeSportIds;
            g.includeSportIds = g.includeSportIds ? uniq(g.includeSportIds) : g.includeSportIds;

            if (g.includeSportIds && g.excludeSportIds) {
                g.excludeSportIds = g.excludeSportIds.filter(id => !g.includeSportIds.includes(id));
            }

            if (g.includeSportIds && g.includeSportIds.some(id => id === 'all')) {
                g.includeSportIds = null;
            }

            return g;
        });

        result.sort((a, b) => {
            const w1 = a.sportSort?.all ?? 0;
            const w2 = b.sportSort?.all ?? 0;
            return w2 - w1;
        });

        return result;
    }

    protected marketGroupUid(g: IMarketGroup): string {
        return `${g.id}:${g.canShowTeamNumbers ? '1' : '0'}:${g.maxRowItems}:${g.useAsDefault ? '1' : '0'}${g.filter ? JSON.stringify(g.filter) : ''}`;
    }
}

