import { IModulesConfig, POSITIONS, MODULES } from '@egamings/sportsbook-core/sportsbook-module-system';

import {
    EnumBannerType,
    EnumBannerPosition,
} from '@egamings/sportsbook-core/sportsbook-banners-components';

export const modulesConfig: IModulesConfig = {
    [POSITIONS.HEADER]: [
        {
            module: MODULES.MAIN_MENU,
        },
        {
            module: MODULES.MOBILE_MAIN_MENU_V2,
            desktop: false,
            mobile: true,
        },
    ],
    [POSITIONS.LEFT_SIDE]: [
        {
            module: MODULES.SEARCH,
            params: {
                type: 'dropdown-left'
            }
        },
        {
            module: MODULES.DELIMITER
        },
        // live game events
        // {
        //     module: MODULES.GAMES_EVENTS,
        //     routes: ['live.events', 'live.events.sport', 'matches.sport.category.tournament.game.live'],
        //     params: {
        //         template: 'sports.categories',
        //         config: {
        //             queryParams: {
        //                 status: ['live'],
        //             },
        //             subscribe: true,
        //             refetch: 30000,
        //         }
        //     }
        // },
        {
            module: MODULES.TOP_EVENTS,
            // for live game events
            // routesExclude: ['live.events', 'live.events.sport', 'matches.sport.category.tournament.game.live'],
        },
        {
            module: MODULES.DELIMITER
        },
        {
            module: MODULES.LEFT_MENU,
            params: {
                items: [
                    {
                        title: gettext(`Today's Events`),
                        link: 'calendar.today',
                        icon: 'down-arrow'
                    },
                    {
                        icon: 'star',
                        title: gettext('My Favorites'),
                        link: 'favourite.matches'
                    }
                ]
            }
        },
        {
            module: MODULES.DELIMITER
        },
        {
            module: MODULES.SPORTS_MENU_TABS
        }
    ],
    [POSITIONS.RIGHT_SIDE]: [
        {
            module: MODULES.BET_SLIP,
            params: {
                resetAfterBet: true,
                showUserBalance: true,
            }
        },
        {
            module: MODULES.DELIMITER
        },
        {
            module: MODULES.WIDGET_MATCH_STATS,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live']
        },
        {
            module: MODULES.BANNERS,
            params: {
                position: 'right'
            }
        },
        {
            module: MODULES.GAMES_EVENTS,
            params: {
                config: {
                    queryParams: {
                        status: ['not_started'],
                        liveodds: true,
                        start_time: {
                            gte: {
                                '@pattern': 'now() + minutes(5)'
                            }
                        },
                        limit: 10,
                        order: {
                            start_time: true,
                            sort: true,
                        }
                    },
                    subscribe: true,
                    refetch: 30000,
                }
            }
        },
    ],
    [POSITIONS.MAIN_TOP]: [
        // main page
        {
            module: MODULES.BANNERS,
            params: {
                type: EnumBannerType.cards,
                position: EnumBannerPosition.main_page_top
            },
            routes: ['home', 'home.upcoming', 'home.live', 'home.highlights','home.promotions']
        },
        {
            module: MODULES.HOME_EVENTS,
            routes: ['home', 'home.upcoming', 'home.live', 'home.highlights', 'home.promotions'],
            params: {
                hideIcons: false
            },
            paramsMobile: {
                hideIcons: false
            }
        },
        // {
        //     module: MODULES.SUPER_TOP,
        //     routes: ['home']
        // },
        // otherwise
        {
            module: MODULES.BREADCRUMBS,
            paramsMobile: {
                showReturnBackBtn: true
            },
            routesExclude: ['home', 'home.upcoming', 'home.live', 'home.highlights','home.promotions', 'profile.history', 'profile.open-bets', 'profile.bonuses', 'home.promotions']
        },
        {
            module: MODULES.GAME_HEADER,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live'],
        },
        {
            module: MODULES.GAME_TIPS,
            routes: ['matches.sport.category.tournament.game', 'matches.sport.category.tournament.game.live'],
        },
    ],
    [POSITIONS.MOBILE_RIGHT_PANEL]: [
        {
            module: MODULES.BET_SLIP,
            params: {
                showUserBalance: true,
                resetAfterBet: true,
            }
        },
    ],
    [POSITIONS.MOBILE_FLOAT_BOTTOM]: [
        // {
        //     module: MODULES.FLOAT_BOTTOM_MENU
        // }
    ],
    [POSITIONS.MAIN_BOTTOM]: [
        {
            module: MODULES.BET_ACCUMULATORS,
            routes: ['home', 'home.upcoming', 'home.live', 'home.highlights','home.promotions'],
        },
    ]
};


