import { modulesConfig } from "./modules.config";

export const configPurple: any ={
    theme: 'default',
    externalComponents: {
        mobileMenu: {
            styles: {
                background:'#7432FF',
                borderColor:'#fff',
                iconColor:'#fff',
                counterBackground:'#202330',
                counterColor:'#fff'
            }
        },
        notification: {
            styles: {
                background:'#282a37',
            }
        }
    },
    modules: modulesConfig,
    layout: {
        scrollable: true,
    },
    svgIcons: {
        mapping: {
            home: {
                replaceName: 'home-v2'
            }
        }
    },
    templates: {
        components: {
            allLeagues: 'v2',
        }
    },
};
