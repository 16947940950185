import { modulesConfig } from './modules.config';
import { EnumMobileMenuType, EnumSportPageTabs } from '@egamings/sportsbook-core/sportsbook-common';
import { DateFormats } from '@egamings/sportsbook-core';
import { EMarketGroupConfigType, esportGroups } from '../../markets';
import { EMarketsCacheGroup } from '@egamings/brsdk';

export const configEsport: any = {
    modules: modulesConfig,
    templates: {
        components: {
            sport: {
                template: 'v3:sport',
            },
            category: {
                template: 'v3:sport',
            },
            tournament: {
                template: 'v3:sport',
            },
            live: {
                template: 'v3:sport',
            },
            topLeagues: {
                template: 'v3:sport',
            },
            allLeagues: 'v2',
            coupon: 'default:coupon',
            gamesPreview: 'default:games-preview',
            game: 'esport',
            outrights: {
                params: {
                    //titleType: 'block-subtitle'
                }
            },
            highlights: {
                template: 'v3:sport',
            },
            upcoming: 'v3:sport',
        }
    },
    dateFormat: {
        [DateFormats.sportToday]: 'HH:mm',
        [DateFormats.sportOther]: 'DD MMMM',
        [DateFormats.sportMobile]: 'DD/MM HH:mm',
        [DateFormats.betHistory]: 'DD MMMM HH:mm',
        [DateFormats.todayAndDate]: '[Today] DD MMMM',
        [DateFormats.today]: '[Today]',
        [DateFormats.dayMonth]: 'DD MMMM',
        [DateFormats.hoursMinutes]: 'HH:mm',
        [DateFormats.sportResults]: 'DD MMMM HH:mm',
        [DateFormats.sportResultsShort]: 'DD MMM HH:mm',
        [DateFormats.calendarDayOfWeek]: 'ddd',
        [DateFormats.calendarDayOfMonth]: 'DD MMM',
        [DateFormats.gameStartTime]: 'LL',
        [DateFormats.topGames]: 'L LT'
    },
    marketGroups: esportGroups,
    marketsCacheGroups: {
        [EMarketGroupConfigType.ALL]: EMarketsCacheGroup.esport,
    },
    liveGames: {
        showGames: false,
        showTournaments: 5,
        hideCount: true
    },
    sportPage: {
        tabsOrder: [
            EnumSportPageTabs.liveGames,
            EnumSportPageTabs.today,
            EnumSportPageTabs.dailyMathList,
            // EnumSportPageTabs.topLeagues,
            EnumSportPageTabs.allLeagues,
            EnumSportPageTabs.outrights,
        ]
    },

    externalComponents: {
        mobileMenu: {
            type: EnumMobileMenuType.coupon,
            styles: {
                background: '#9B55F4',
                borderColor: '#fff',
                iconColor: '#fff',
                counterBackground: '#9B55F4',
                counterColor: '#fff'
            }
        }
    },
    sportIcons: {
        set: 'set2'
    },
};
