import { Injectable } from '@angular/core';
import get from 'lodash-es/get';

@Injectable()
export class ExtendConfig {

    protected static config?: object = {};

    public static get(configPath: string) {
        return get(this.config, configPath);
    }
}
